@font-face {
  font-family: FontAwesome;
  src: url(../fonts/fontawesome-webfont.woff2) format('woff2'), url(../fonts/fontawesome-webfont.woff) format('woff');
  font-weight: 400;
  font-style: normal
}

.saveholder {
  clear: both;
  padding: 10px;
  text-align: center;
}

#gotoMainContent {
  color: transparent;
  position: absolute;
  left: 10px;
  top: 10px;
  &:link, &:visited {
    color: transparent;
  }
  @include outlineOnFocusNoBorder();
  &:focus {
    color: $colorLinks;
  }
}

.logo {
  margin-top: 14px;
  display: block;
  margin-bottom: 40px;

  > a {
    display: inline-block;
  }
  .homeLinkLogo > img {
    max-width: 100%;
    max-height: 200px;
  }
  @media screen and (max-width: $screen-xs) {
    .homeLinkLogo > img {
      max-width: 90%;
    }
    .homeLinkLogo {
      text-align: center;
    }
  }
}

/* keep the footer at the bottom of the page */
$footer_height: 1.6em;
$footer_line_height: 1.5em;

.over_footer_wrapper {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  margin: 0 auto (-1 * $footer_height);
}

a {
  @include outlineOnFocusNoBorder();
}

body > footer {
  height: $footer_height;
  max-width: $container-md;
  margin-left: auto;
  margin-right: auto;
  padding-right: floor(($grid-gutter-width / 2));
  padding-left: ceil(($grid-gutter-width / 2));

  p {
    height: $footer_height;
    line-height: $footer_line_height;
    margin-bottom: 0;
    margin-top: 0;
    white-space: nowrap;
  }

  a:link, a:visited {
    color: $colorLinksFooter;
  }

  .version {
    display: inline-block;
    margin-left: 30px;
    font-size: 0.8em;
  }
  @media print {
    display: none;
  }
}

.footer_spacer {
  height: $footer_height;
}

.labelSubInfo {
  font-weight: normal;
  font-size: 0.8em;
}

.antragsgruen-content {
  // The children should behave similar to bootstrap's grids (hence the paddings&floats), but have a configurable width

  // Amendment-Markers at the side of a motion should be above the sidebar in case of a collision
  > main {
    z-index: 1;
  }

  > #sidebar {
    max-width: $sidebarWidth;
    float: left;
    z-index: 0;
    padding-right: floor(($grid-gutter-width / 2));
    padding-left: ceil(($grid-gutter-width / 2));
  }

  > .antragsgruen-width-main {
    width: 100%;

    max-width: $mainContentWidth;
    float: left;
  }

  > .antragsgruen-width-full {
    width: 100%;
    float: left;
  }

  > * {
    position: relative;
    min-height: 2px;
  }
}

.goBackLink {
  display: inline-block;
  margin-bottom: 20px;
}

.saveCancelRow {
  overflow: auto;
  .well &.content {
    overflow: auto;
  }
  .saveCol {
    float: right;
  }
  .cancelCol {
    float: left;
  }
}

.toolbarBelowTitle {
  padding: 10px 19px;
  border-bottom: solid 1px #aaa;
  background: #f7f7f7;
  display: table;
  margin-left: -1px;
  margin-right: -1px;
  width: calc(100% + 2px);
  > * {
    display: table-cell;
  }
}

.stickyAdminDebugFooter {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10;
  padding: 0;
  background: white;
  border-top: solid 1px #ccc;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  display: table;
  width: 100%;

  > * {
    display: table-cell;
    padding: 5px;
    vertical-align: top;
  }
  .adminHint {
    font-size: 0.8em;
    display: block;
  }
  .setterCol {
    width: 100%;
  }
  #simulateAdminTime {
    width: 200px;
    float: left;
    margin-right: 10px;
  }
  h2 {
    white-space: nowrap;
    margin: 0;
    font-size: 1.1em;
  }
  label {
    margin: 0;
  }
}

*:fullscreen {
  overflow-x: hidden;
  overflow-y: auto;
}
*:-webkit-full-screen {
  overflow-x: hidden;
  overflow-y: auto;
}
*:-moz-full-screen {
  overflow-x: hidden;
  overflow-y: auto;
}
*:-ms-fullscreen {
  overflow-x: hidden;
  overflow-y: auto;
}
