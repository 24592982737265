.proposedChangesOpener {
  padding-left: calc(30% + 8px);
  margin-top: -20px;
  margin-bottom: 20px;
}

#proposedChanges {
  background-color: #f8faf8;
  border: solid 1px #eee;
  border-radius: 4px;
  margin: 0 20px 30px;

  h2 {
    margin: 0;
    font-size: 18px;
    padding: 5px 10px;
    background-color: #ded;
  }

  .closeBtn {
    margin-right: -10px;
    margin-top: -2px;
  }

  .holder {
    display: flex;
    > * {
      flex: 1;
      padding: 10px;
    }
  }
  .statusForm {
    width: 40%;
  }
  .proposalCommentForm {
    width: 40%;
    display: flex;
    flex-direction: column;
    border-left: solid 1px #eee;
  }
  .middleCol {
    width: 20%;
    display: flex;
    flex-direction: column;
  }
  .votingBlockSettings {
    padding-bottom: 10px;
  }
  .visibilitySettings {
    label {
      display: block;
    }
  }
  .notificationStatus {
    font-size: 12px;
    .accepted {
      color: green;
      margin-right: 5px;
    }
    .rejected {
      color: red;
      margin-right: 5px;
    }
    .notSavedHint {
      font-style: italic;
      color: #777;
    }
    .notifyProposer {
      white-space: normal;
      text-align: left;
      font-weight: normal;
    }
    .setConfirmationStatus {
      overflow: auto;
    }
    .setConfirmation {
      float: right;
      font-weight: normal;
    }
    .sendAgain {
      float: left;
      font-weight: normal;
    }
  }

  h3, .headingLabel {
    font-size: 14px;
    margin: 3px 0 8px 0;
    font-weight: bold;
  }
  label {
    font-size: 12px;
    font-weight: normal;
  }
  .newBlock {
    .control-label {
      font-size: 14px;
      font-weight: bold;
    }
  }
  .commentList {
    flex: 1;
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: block;
    max-height: 130px;
    overflow-y: auto;
    background-color: #fbfdfb;
    border: solid 1px #dfd;

    > li {
      margin: 0;
      padding: 0;
      display: block;
    }
    .header {
      background-color: #eee;
      overflow: auto;
      margin-top: 10px;
    }
    .date {
      float: right;
    }
    .delComment {
      float: right;
      color: $colorDelLink;
      width: auto;
    }
    .overv {
      text-align: right;
      font-style: italic;
    }
  }
  .proposalCommentForm {
    font-size: 12px;

    textarea {
      border-radius: 0;
      border: solid 1px #eee;
      border-bottom: none;
      box-shadow: none;
      font-size: 12px;
    }
    button {
      margin-top: -1px;
      width: 100%;
    }
  }
  .statusDetails, .collisions, .publicExplanation, .notifyProposerSection {
    border-top: solid 1px #eee;
    padding: 10px;
  }
  .notifyProposerSection {
    .proposalFrom {
      margin-bottom: 15px;
    }
    .submitRow {
      text-align: center;
      margin-top: 10px;
    }
  }
  .saving, .saved {
    border-top: solid 1px #eee;
    height: 40px;
    text-align: center;
    line-height: 38px;
    vertical-align: middle;
    display: none;
  }
  .showIfChanged {
    display: none;
  }
  &.isChanged .showIfChanged {
    display: block;
  }
  &.isChanged .hideIfChanged {
    display: none;
  }
  &.showSaved .saved {
    display: block;
  }
  &.noStatus .showIfStatusSet {
    display: none;
  }
}

#proposedChangeTextForm {
  h2 {
    font-size: 24px;
  }
  .motionTextHolder {
    margin-top: 20px;
    .title {
      font-weight: bold;
    }
    .paragraph .text {
      padding: 5px 10px 10px 0;
      > * {
        padding-bottom: 5px;
        padding-top: 5px;
        margin-bottom: 5px;
      }
    }
  }
  .originalVersion {
    ins.ice-del, del.ice-del {
      display: inline;
    }
  }
  .save-row {
    text-align: center;
    margin: 0 0 40px 0;
  }
  #collisionIndicator {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    background: white;
    border-top: 1px solid #ccc;
    -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
    padding: 5px;

    h2 {
      display: inline-block;
      margin: 0;
      padding: 0;
      font-size: inherit;
      font-weight: bold;
    }

    .collisionList {
      display: inline-block;
      margin: 0;
      padding: 0;
      list-style-type: none;
      > li {
        display: inline-block;
        margin: 0 20px;
      }
    }
  }
}

.proposedProcedureToolbar {
  .right {
    > * {
      float: right;
      margin-left: 15px;
    }
  }
  .left {
    line-height: 40px;
    vertical-align: middle;
  }
  .currentDate {
    font-size: 0.8em;
    color: gray;
  }
}

.proposedProcedureOverview {
  &.openable {
    margin-bottom: 20px;
  }
  h2 {
    a, a:link, a:hover, a:active {
      color: #fff;

    }
  }

  table {
    table-layout: fixed;
    margin-top: 25px;
    &:first-of-type {
      margin-top: 0;
    }
  }
  caption {
    font-weight: bold;
    background-color: #eee;
    color: black;
    text-align: center;
  }
  .procedure {
    h3 {
      // Motion text
      margin: 7px 0 0 0;
      font-size: 0;
      line-height: 0;
      color: transparent;
      border-top: solid 1px lightgrey;
    }
    p {
      margin: 0;
      font-size: inherit;
    }
    h4 {
      margin: 10px 0 5px 0;
      font-size: inherit;
      font-weight: bold;
    }
  }
  .visible {
    width: 5%;
    text-align: center;
  }
  .prefix {
    .amendmentAjaxTooltip {
      float: right;
      opacity: 0.5;
    }
  }
  @media screen and (min-width: 700px) {
    .prefix {
      width: 125px;
    }
    .initiator {
      width: 270px;
    }
  }
  @media screen and (min-width: 1400px) {
    .comment {
      max-width: 350px;
    }
  }
  @media screen and (max-width: 500px) {
    display: block;
    tr, td, th, tbody, thead, caption {
      display: block;
    }
    .prefix {
      display: inline-block;
      border-top: none;
    }
    .initiator {
      display: inline-block;
      border-top: none;
      &:before {
        content: '(';
      }
      &:after {
        content: ')';
      }
    }
    .procedure {
      border-top: none;
      border-bottom: solid 1px #ddd;
    }
  }
  .explanation {
    font-size: 0.8em;
    margin-top: 5px;
    color: #888;
  }
  .notVisible {
    font-size: 0.8em;
    color: $adminHintColor;
    font-style: italic;
  }
  tr.withdrawn {
    .prefix, .initiator {
      text-decoration: line-through;
    }
  }
  tr.moved {
    .prefix, .initiator, .procedure {
      opacity: 0.4;
    }
  }
  tr.accepted {
    background-color: #ddffdd;
  }
  tr.vote {
    background-color: #ffe0cc;
  }
  h2 .withdrawn {
    font-size: 0.8em;
    color: #eee;
  }
  .contactShow {
    display: block;
    font-size: 0.7em;
  }
  .contactDetails {
    font-size: 0.8em;
    *[title] {
      cursor: help;
    }
  }
  .comments {
    .writing {
      display: none;
    }
    &.writing {
      .writing {
        display: block;
      }
      .notWriting {
        display: none;
      }
    }
    .cancelWriting {
      color: gray;
    }
    .commentList.hasContent {
      max-height: 200px;
      overflow: auto;
    }
    .comment {
      overflow: hidden; // Spacing for button

      &.template {
        display: none;
      }
      .name {
        font-weight: bold;
      }
    }
  }
}

.agreeToProposal {
  background-color: #f8faf8;
  border: solid 1px #eee;
  border-radius: 4px;
  margin: 0 20px 30px;

  h2 {
    margin: 0;
    font-size: 18px;
    padding: 5px 10px;
    background-color: #ded;
  }
  .holder {
    display: flex;
    > * {
      flex: 1;
      padding: 10px;
    }
  }
  .hint {
    border-top: solid 1px #eee;
    padding: 5px 10px;
    font-style: italic;
  }
  .status {
    .head {
      font-weight: bold;
    }
  }
  .agreement {
    .agreed {
      color: green;
    }
  }
}
